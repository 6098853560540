import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'

const AsansorSeperatorPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Asansör Seperatör Teli - Erez Kaynak'
        description='Asansör seperatör hasır teli, karşı ağırlık veya dengeleme ağırlığının hareket güzergahı ve asansör kuyusunun bir bölme vasıtası gibi kullanarak korumasında kullanılmaktadır. Firmamız parmak yada el geçirmeyecek şekilde 20x20mm yada 25x25mm göz aralığında istenilen ebatlarda asansör seperatör teli imalatı yapmaktadır.'
        keywords='Asansör Seperatör Teli,asansör boşluğu teli,asansör kuyu teli,asansör kuy bölme teli,asansör ağırlık koruma teli,asansör güvenlik teli,asansör seperatör,25x25x3 seperatör tel,20x20x3 seperatör tel,asansör seperatör tel fiyatı,seperatör teli,seperatör tel fiyatı,20x20x3 seperatör teli'
        url='asansor-seperator-teli'
        imgurl={getImageByName('paslanmazhasirtel5').sm.src}
      />
      <ImgLightBox
        ref={lightBoxRef}
        gallery={[
          getImageByName('paslanmazhasirtel5').original.src,
          getImageByName('asansorseperatorteli2').original.src,
          getImageByName('asansorseperatorteli').original.src,
        ]}
      />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Asansör Seperatör Hasır Tel İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                <strong>Asansör seperatör hasır teli,</strong> karşı ağırlık veya dengeleme ağırlığının hareket güzergahı ve asansör
                kuyusunun bir bölme vasıtası gibi kullanarak korumasında kullanılmaktadır.
              </p>
              <p>
                Firmamız parmak yada el geçirmeyecek şekilde 20x20mm yada 25x25mm göz aralığında istenilen ebatlarda{' '}
                <strong>asansör seperatör teli</strong> imalatı yapmaktadır.
              </p>
              <p>
                <strong>Asansör seperatör hasır teli</strong> paslanmaz tel, galvaniz tel ve ham çelik tel malzemeden yapılabilmektedir.
              </p>
              <p>
                Ayırıcı <strong>asansör seperatör hasır tel</strong> ile yapılan bölme asansör üzerinde çalışan personelin güvenliğini
                sağlamaktadır.
              </p>
            </ContentText>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('paslanmazhasirtel5')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            {/* <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box> */}
          </Grid>
        </Grid>
        <ContentText>
          <p>
            Asansör seperatör hasır tellere Elektrostatik toz fırın boya işlemi yapılabilmektedir. Standart yada özel renk seçenekleri ile
            hasır teller elektrostatik toz boya ile boyanmaktadır. Firmamız bünyesinde boyasız olarak hasır tel imalatı gerçekleştirip,
            çalışmış olduğumuz elektrostatik toz boya yapan firmalar ile ihtiyacınız karşılanmaktadır.
          </p>
          <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
        </ContentText>
        <Box textAlign='center' mt={3}>
          <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
        </Box>
      </Box>
      <Grid container justify='center' spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
          <ImgWrapper onClick={() => lightBoxRef.current.openLightBox(1)}>
            <MediumImage lazyHeight={500} img={getImageByName('asansorseperatorteli2')} alt='Özel hasır tel imalatı' />
          </ImgWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
          <ImgWrapper onClick={() => lightBoxRef.current.openLightBox(2)}>
            <MediumImage lazyHeight={500} img={getImageByName('asansorseperatorteli')} alt='Özel hasır tel imalatı' />
          </ImgWrapper>
        </Grid>
      </Grid>
      <GeneralCarousel />
    </>
  )
}

export default AsansorSeperatorPage
